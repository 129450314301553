<!-- 添加渠道客户 -->
<template>
  <el-dialog
    width="40%"
    center
    :visible="visible"
    :lock-scroll="false"
    :destroy-on-close="true"
    custom-class="ele-dialog-form"
    :title="isUpdate?'设置权限':'设置权限'"
    @update:visible="updateVisible">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-position="left"
      label-width="130px">
      <el-form-item label="显示设置:">
        <el-checkbox v-model="form.display_setup.display_details">详情页</el-checkbox>
        <!--<el-checkbox v-model="checked">编辑页面</el-checkbox>-->
        <el-checkbox v-model="form.display_setup.display_list">列表页</el-checkbox>
        <!--<el-checkbox v-model="checked">小程序下单端显示（C端下单）</el-checkbox>-->
        <!--<el-checkbox v-model="checked">小程序下单端显示（B端下单）</el-checkbox>-->
        <!--<el-checkbox v-model="checked">司机端显示（自营司机）</el-checkbox>-->
        <!--<el-checkbox v-model="checked">司机端显示（共享司机）</el-checkbox>-->
      </el-form-item>

      <el-form-item label="使用者设置:">
        <el-radio-group v-model="form.if_user" @change="selectif_user">
          <el-radio :label="0">全部可用</el-radio>
          <el-radio :label="1">部分可用 (已选 {{form.user_count}} 家)</el-radio>
        </el-radio-group>
      </el-form-item>

      <!--服务商列表  部分可用时显示-->
      <div v-if="form.if_user == 1">
        <el-row :gutter="15" style="margin-bottom: 10px;">
          <el-col :lg="12" :md="12">
            <el-form-item label="关键词">
              <el-input v-model="where.keyword" placeholder="请输入关键词" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="12" style="text-align: center;">
            <div class="ele-form-actions">
              <el-button
                type="primary"
                class="ele-btn-icon"
                @click="QueryList">查询
              </el-button>
              <el-button @click="empty">清空</el-button>
            </div>
          </el-col>
        </el-row>
        <!-- 数据表格 -->
        <my-table
          ref="table"
          :columns="columns"
          :tableData="List"
          :cellStyle="cellStyle"
          :headerCellStyle="headerCellStyle"
          :rowClassName="rowClassName"
          :total="total"
          :customsFromWhere="where"
          :loading="loading1"
          @select="select"
          @selectAll="selectAll"
          @ChangeSize="ChangeSize"
          @currentChange="currentChange"
        >
          <!-- 不使用数据可不接收scope值 -->
          <!--<template v-slot:operationSlot= "{ scope }">-->
          <!--  <el-link type="success" :underline="false" @click="FormDesign(scope.row)">设计表单</el-link>-->
          <!--  <el-link type="primary" :underline="false" @click="allocation(scope.row)">查看组件</el-link>-->
          <!--</template>-->
        </my-table>
      </div>


      <el-form-item label="会员设置:">
        <el-checkbox v-model="form.vip.vip_setup">VIP</el-checkbox>
        <!--<el-checkbox v-model="checked">高级</el-checkbox>-->
        <el-checkbox v-model="form.vip.free_setup">免费</el-checkbox>
      </el-form-item>

    </el-form>


    <div slot="footer">
      <el-button
        @click="updateVisible(false)">取消
      </el-button>
      <el-button
        style="margin-left: 20px;"
        type="primary"
        :loading="loading"
        @click="save">提交
      </el-button>
    </div>

  </el-dialog>
</template>

<script>

//引入的接口
import {facilitator_tables, form_assistread, form_assistrules} from "@/api/system";
import Vue from "vue";

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: {
        display_setup:{},
        if_user:'',
        user_count: 0,
        use_ids:[],
        vip:{},
      },
      // 表单验证规则
      rules: {
        realname: [
          {required: true, message: '请输入姓名', trigger: 'blur'}
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ],
        license_plate_number: [
          {required: true, message: '请输入车牌号', trigger: 'blur'}
        ],
        items: [
          { type: 'array', required: true, message: '请选择服务类型', trigger: 'change' }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 服务商相关
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading1:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "id",
          prop: "id",
          isShow: true
        },
        {
          label: "服务商名称",
          prop: "company_name",
          isShow: true
        },
        {
          label: "负责人",
          prop: "realname",
          isShow: true
        },
        {
          label: "机构码",
          prop: "institution_code",
          isShow: true
        },
        // {
        //   label: "操作",
        //   width: "200",
        //   slot: "operationSlot",
        //   isShow: true
        // },
      ],
      // 当前勾选的数据
      selection:[],

    };
  },

  mounted() {
    if (this.data) {
      console.log(this.data)
      facilitator_tables(this.where).then(async (relut) => {
        this.total = relut.data.count;
        this.List = relut.data.list;
        this.loading1 = false;

        // 获取详情
        form_assistread(this.data.id).then(res => {
          if(res.code === 200){
            this.form = res.data;
            // console.log(this.form)
            this.isUpdate = true;

            console.log(this.List,'List')
            // 默认勾选的数据
            let data = [];
            res.data.use_ids.forEach(row => {
              this.List.forEach(item => {
                if(row == item.id){
                  console.log(item)
                  data.push(item);
                }
              });
            });
            console.log(data,'data')
            this.$nextTick(() => {
              console.log(this.$refs)
              console.log(this.$refs.form)
              console.log(this.$refs.table)
              data.forEach(shuju => {
                this.$refs.table.$refs.table.toggleRowSelection(shuju,true);
              });
            })




          }
        })

      }).catch((err) => {
        this.$message.error(err.msg);
        this.loading1 = false;
      })

    } else {
      this.form = {};
      this.isUpdate = false;
    }

  },
  methods: {
    // 获取服务商
    getList() {
      facilitator_tables(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading1 = false;
      }).catch((err) => {
        this.$Message.error(err.msg);
        this.loading1 = false;
      })
    },
    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },
    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      this.selection = selection;
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },
    // 表格全选事件
    selectAll(list){
      console.log(list)
      this.selection = list;
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {

      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    //选择使用者设置
    selectif_user(value){
      console.log(value)
      //调用获取服务商
      this.getList();
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          //判断是新增还是修改
          if(this.isUpdate === false){
            // Addclone_drivers(this.form).then(res => {
            //   console.log(res)
            //   this.loading = false;
            //   if (res.code === 200) {
            //     this.$message.success(res.msg);
            //     this.form = {};
            //     this.updateVisible(false);
            //     this.$emit('done');
            //   } else {
            //     this.$message.error(res.msg);
            //   }
            // }).catch(e => {
            //   this.loading = false;
            //   this.$message.error(e.msg);
            // })
          }else {
            let data = this.form;
            data.use_ids = [];
            this.selection.forEach(item => {
              data.use_ids.push(item.id)
            })
            form_assistrules(this.data.id,data).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.form = {};
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">
</style>
